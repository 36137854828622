import React from 'react'
import Layout from "../layouts/de"
import Block from "../components/Block"

const InfoPage = (props) => (
  <Layout 
    location={props.location} 
    title="Privacidad - Agencia creativa online"
    description="Aquí encontrará todos los datos importantes sobre la protección de datos. También puede enviar su consulta a mi dirección de correo electrónico mail@lopezdesign.de"
    >
    <Block color="white"
      left={
        <div className="grid__item space--top">
          <article className="space--bottom">
            <h1 className="shine space--bottom">Privacidad</h1>
            <p>La persona responsable en términos de leyes de protección de datos, en particular el Reglamento General de Protección de Datos de la UE (DSGVO), es: Cristian López</p>
            <h5>Sus derechos como interesado</h5>
            <p>Puede ejercer los siguientes derechos en cualquier momento utilizando los datos de contacto facilitados por nuestro responsable de la protección de datos:</p>
            <ul>
              <li>Información sobre sus datos almacenados por nosotros y su tratamiento (Art. 15 DSGVO),</li>
              <li>Corrección de datos personales inexactos (Art. 16 GDPR),</li>
              <li>Eliminación de sus datos almacenados con nosotros (Art. 17 DSGVO),</li>
              <li>Restricción del tratamiento de datos si todavía no se nos permite eliminar sus datos debido a obligaciones legales (art. 18 DSGVO),</li>
              <li>oponerse al tratamiento de sus datos por nuestra parte (art. 21 del DSGVO) y</li>
              <li>Portabilidad de los datos, siempre que usted haya consentido el tratamiento de los datos o haya celebrado un contrato con nosotros (art. 20 de la DSGVO).</li>
            </ul>
            <p>Si nos ha dado su consentimiento, puede revocarlo en cualquier momento con efecto para el futuro.</p>
            <p>Puede dirigirse en cualquier momento a una autoridad de control para presentar una queja, por ejemplo, a la autoridad de control competente en el estado federal de su residencia o a la autoridad que nos corresponda como organismo responsable.</p>
            <p>Una lista de las autoridades de supervisión (para el sector no público) con la dirección puede encontrarse en: <a target="_blank" rel="noreferrer" href="https://www.bfdi.bund.de">https://www.bfdi.bund.de</a>.</p>
          </article>
          <article className="space--bottom">
            <h5>Uso de Google Analytics</h5>
            <p>Si ha dado su consentimiento, este sitio web utiliza Google Analytics, un servicio de análisis web prestado 
              por Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 EE.UU. (en adelante, "Google"). Google 
              Analytics utiliza "cookies", que son archivos de texto ubicados en su ordenador, para ayudar al sitio web a 
              analizar el uso que hacen los usuarios del mismo. La información generada por la cookie sobre su uso de este 
              sitio web se transmite generalmente a un servidor de Google en los Estados Unidos y se almacena allí. Sin 
              embargo, debido a la activación de la anonimización de la IP en estos sitios web, su dirección IP será truncada 
              previamente por Google dentro de los estados miembros de la Unión Europea o en otros estados contratantes del 
              Acuerdo sobre el Espacio Económico Europeo. Sólo en casos excepcionales se transmitirá la dirección IP completa 
              a un servidor de Google en Estados Unidos y se acortará allí. La dirección IP transmitida por su navegador como 
              parte de Google Analytics no se fusionará con otros datos de Google.
            </p>
            <p>Puede encontrar más información sobre las condiciones de uso y la protección de datos en <a target="_blank" rel="noreferrer" href="https://www.google.com/analytics/terms">https://www.google.com/analytics/terms</a>.</p>
            <p>En nombre del operador de este sitio web, Google utilizará esta información con el fin de evaluar su uso del 
              sitio web, recopilar informes sobre la actividad del sitio web y prestar otros servicios relacionados con la 
              actividad del sitio web y el uso de Internet al operador del sitio web.
            </p>
            <p>
              Los datos enviados por nosotros y vinculados a las cookies, a los identificadores de usuario (por ejemplo, 
              el identificador de usuario) o a los identificadores de publicidad se eliminan automáticamente después de 14 
              meses. La supresión de los datos cuyo periodo de conservación se ha alcanzado tiene lugar automáticamente una 
              vez al mes.
            </p>
            <h5>Retirada del consentimiento:</h5>
            <p>
              Puede evitar el seguimiento de Google Analytics en nuestro sitio web haciendo clic en este enlace. Esto 
              instalará una cookie de exclusión en su dispositivo. Esto evitará la recopilación por parte de Google Analytics 
              para este sitio web y para este navegador en el futuro, siempre y cuando la cookie permanezca instalada en su 
              navegador.
            </p>
            <p>
              También puede rechazar el uso de cookies seleccionando la configuración adecuada en su navegador. software de 
              navegación; sin embargo, nos gustaría señalar que en este caso es posible que no pueda utilizar todas las En 
              este caso, es posible que no pueda utilizar todas las funciones de este sitio web en toda su extensión.
            </p>
            <p className="space--bottom">
              Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Webseite 
              bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, 
              indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: <a href="https://tools.google.com/dlpage/gaoptout?hl=de">Browser Add On 
              zur Deaktivierung von Google Analytics</a>.
            </p>
            <h5>Uso de bibliotecas de scripts (Google Web Fonts)</h5>
            <p>Con el fin de mostrar nuestro contenido correctamente y de forma gráficamente atractiva en todos los navegadores, utilizamos "Google Web Fonts" de Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; en adelante "Google") en este sitio web para mostrar las fuentes.</p>
            <p className="space--bottom">Puede encontrar más información sobre Google Web Fonts en <a target="_blank" rel="noreferrer" href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a> y en la política de privacidad de Google: <a target="_blank" rel="noreferrer" href="https://policies.google.com/privacy">https://www.google.com/policies/privacy/</a>.</p>
            <h5>Información sobre su derecho de oposición según el artículo 21 de la DSGVO</h5>
            <h5>Derecho a objetar caso por caso</h5>
            <p>
              Tiene derecho a oponerse en cualquier momento, por motivos relacionados con su situación particular, al tratamiento 
              de datos personales que le conciernen y que se lleva a cabo sobre la base del artículo 6, apartado 1, letra f), del 
              DSGVO (tratamiento de datos sobre la base de un equilibrio de intereses); esto también se aplica a la elaboración de 
              perfiles basada en esta disposición en el sentido del artículo 4 nº 4 del DSGVO.
            </p>
            <p>
              Tiene derecho a oponerse en cualquier momento, por motivos relacionados con su situación particular, al 
              tratamiento de datos personales que le conciernen y que se lleva a cabo sobre la base del artículo 6, apartado 
              1, letra f), del DSGVO (tratamiento de datos sobre la base de un equilibrio de intereses); esto también se aplica 
              a la elaboración de perfiles basada en esta disposición en el sentido del artículo 4 nº 4 del DSGVO.
            </p>
            <h5>Destinatario de una oposición</h5>
            <p>Cristian López</p>
          </article>
          <article className="space--bottom">
            <h5>Cambio en nuestra política de privacidad</h5>
            <p>
              Nos reservamos el derecho de adaptar esta declaración de protección de datos para que se ajuste siempre a los 
              requisitos legales vigentes o para realizar cambios en nuestros servicios en la declaración de protección de 
              datos, por ejemplo, al introducir nuevos servicios. La nueva declaración de protección de datos se aplicará 
              entonces a su próxima visita.
            </p>
          </article>
          <article className="space--bottom">
            <h5>Preguntas al responsable de la protección de datos</h5>
            <p>
              Si tiene alguna pregunta sobre la protección de datos, escríbanos un correo electrónico o póngase en contacto 
              directamente con la persona responsable de la protección de datos en nuestra organización:
            </p>
            <p>Cristian López</p>
            <p>La política de privacidad fue creada con la ayuda de activeMind AG, los expertos en protección de datos externos (versión #2020-09-30).</p>
          </article>
        </div>
      }
    />
  </Layout>
)

export default InfoPage

